import React from "react";
import "./css/Director.css";

const Chairman = () => {
  return (
    <>
      <div className="director-sid">
        <div className="director-sid-row">
          <div className="director-sid-col chair">
            <img src="./img/chair1.jpeg" />
            <h5>Mr.Shyam Narayan Mishra</h5>
          </div>
          <div className="director-sid-col">
            <h2>Director Message</h2>
            <h6>
              Dear Parent & Students, It gives me great pleasure to welcome you
              to Naveen Vidya Bharti Hr. Sec. School. A child’s world is a
              beautiful yet mysterious tapestry made up of a multitude of hopes
              and aspirations dyed in incandescent colours of thought and deed
              and their world is very much influenced by the school environment
              as they spend most of their childhood in school. Thus, schooling
              has the greatest influence on an individual’s life. It should
              enable the child to blossom into a well-balanced person, healthy
              in mind and spirit. Let education help dear Aryans to empower
              themselves and transform the world into a place where truth,
              justice, liberty, and equality have the sway. As I glance back at
              the performance of the children in various activities during the
              last academic year, be it curricular, co-curricular, I can
              confidently say that our students have certainly made us, parents
              and teachers proud of their achievements. The school endeavours to
              equip its students to excel in academics, and to be savvy in the
              use of technology. But equally, knowledge is to become a
              liberating experience as the child is encouraged to discover his
              interests, his talents and aptitudes, and learn to form his
              opinions and role in society. We, at The NVBHSS, strive hard to
              create more interesting and meaningful learning experiences,
              through which our student will be able to develop the most
              relevant skills, habits, and character attributes. We see no
              substitute for the patient efforts of teacher and student sitting
              at the table and in regular consultation, learning together. We
              are a community of learners, where excellence is a shared pursuit.
              We learn together in a living community where each of us is a
              valued citizen with important contributions to make. The implicit
              promise of The NVBHSS School has always been a transformative
              education, rooted in the highest standards of academic excellence,
              nurtured by The NVBHSS values, and enriched by the opportunity to
              learn in the best settings. May you all continue to scale greater
              and insurmountable heights of excellence. I take this opportunity
              to congratulate the principal, staff, and students; every effort
              of the team is highly appreciated who has contributed towards
              making learning so meaningful.. I welcome you to be a part of
              NVBHSS family!
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chairman;
