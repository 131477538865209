import React from 'react'


const Updates = () => {
  return (
  <>
  <div className="updates">
        <div className="updatesleft">
            <div className="innerup">
                Updates
            </div>
        </div>
        <div className="upinner">
            <a href=""><p>1. Admission closed for 2023-2024</p></a>
            <a href=""><p>2. School Transport Arrangement</p></a>
        </div>
  </div>
  </>
  )
}

export default Updates