// import React, { useEffect, useState } from "react";

// const Banner = () => {
//   const [slides, setSlides] = useState([]);
//   const [selectedSlide, setSelectedSlide] = useState(0);


//   useEffect(() => {

//     fetch('https://cms.maitretech.com/newwisdom/items/slider?fields=*.*.*')
//   .then(response => response.json())
//   .then(data => console.log(data))
//   .catch(error => console.error('Error:', error));
//   }, []);
//   return (
//     <>
//       <div
//         id="carouselExampleInterval"
//         class="carousel slide"
//         data-bs-ride="carousel"
//       >
//         <div class="carousel-inner jaimataran">
//           <div class="carousel-item active" data-bs-interval="5000">
//             <img src="./img/s1.jpeg" class="d-block w-100" alt="..." />
//           </div>
//           <div class="carousel-item" data-bs-interval="4000">
//             <img src="./img/slide2.jpeg" class="d-block w-100" alt="..." />
//           </div>
//           <div class="carousel-item">
//             <img src="./img/slide3.jpeg" class="d-block w-100" data-bs-interval="4000" alt="..." />
//           </div>
     
//         </div>
//         <button
//           class="carousel-control-prev"
//           type="button"
//           data-bs-target="#carouselExampleInterval"
//           data-bs-slide="prev"
//         >
//           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
//           <span class="visually-hidden">Previous</span>
//         </button>
//         <button
//           class="carousel-control-next"
//           type="button"
//           data-bs-target="#carouselExampleInterval"
//           data-bs-slide="next"
//         >
//           <span class="carousel-control-next-icon" aria-hidden="true"></span>
//           <span class="visually-hidden">Next</span>
//         </button>
//       </div>
//     </>
//   );
// };

// export default Banner;


import React, { useEffect, useState } from "react";

const Banner = () => {
  const [slides, setSlides] = useState([]);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    fetch('https://cms.maitretech.com/newwisdom/items/slider?fields=*.*.*')
      .then(response => response.json())
      .then(data => {
        // Assuming the slides array is in the data property
        const apiSlides = data.data || [];

        // Set the slides state
        setSlides(apiSlides);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <>
      <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner jaimataran">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`carousel-item ${index === selectedSlide ? 'active' : ''}`}
              data-bs-interval="5000"
            >
              <img src={slide.image.data.full_url} className="d-block w-100" alt={`Slide ${index}`} />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
          onClick={() => setSelectedSlide((selectedSlide - 1 + slides.length) % slides.length)}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
          onClick={() => setSelectedSlide((selectedSlide + 1) % slides.length)}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Banner;
