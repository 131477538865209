import React from "react";

const Ourschool = () => {
  return (
    <>
      <div className="our-school">
        <div className="our-row">
          <div className="our-col">
            <h1>About Us</h1>
            <p>
            New Wisdom Public School was established in 2000 and its been quite
             successful 19 years. New Wisdom Public School is promoted by Divya 
             Drishti Viklang Evam Samaj Seva Samiti it is a non- profit organization 
             which mainly focus in helping the handicap and disable people to achieve
              education and other benefits. New Wisdom Public School is situated in
               Rajeev Nagar, Ayodhya By Pass Road, Bhopal and it has classes from Nursery
                to 12th. In all those years New Wisdom Public School has developed astonishing results in 10th and 12th. Since 2000 we have produced some budding minds who will prove their mantle in the coming years. We try to keep our focus on studies and we believe that with proper guidance and positive thinking the student will easily achieve a respectable result in their exams. New Wisdom Public School believe in providing quality education and due this we keep a proper Teacher:Student ratio.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourschool;
